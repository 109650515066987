<template>
  <div class="register">
      <div class="title">
<!-- <img class="img" src="../assets/img/logo.png" alt="" > -->
<!-- <img src="" alt=""> -->
      </div>
      <div class="registerbox">
          
      <el-row  class="row-bg" >
  <el-col :span="24">
       <el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>企业营业执照</span>
   
  </div>
  <el-form ref="form" :disabled="formdis" :inline="true" :model="form" label-width="100px" class="form">
 
            <el-form-item label="营业执照名称">
    <el-input v-model="form.j_zzmc" :disabled="j_zzmc"></el-input>
  </el-form-item>
  <el-form-item label="营业执照代码">
    <el-input v-model="form.j_zch" :disabled="j_zch"></el-input>
  </el-form-item>
  <el-form-item label="营业场所">
    <el-input v-model="form.j_yycs" :disabled="j_yycs"></el-input>
  </el-form-item>
  <el-form-item label="法人" prop="j_fr">
            <el-input placeholder="" v-model="form.j_fr" class="input-with-select">
            
            </el-input>
            </el-form-item>

  <el-form-item label="经营范围" prop="j_jyfw" >
    <el-input v-model="form.j_jjfw"></el-input>
  </el-form-item>
  <el-form-item label="成立日期" prop="j_clrq">
       <el-date-picker
      v-model="form.j_clrq"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
    <!-- <el-input v-model="form.j_clrq"></el-input> -->
  </el-form-item>
  <el-form-item label="发证机关" prop="j_fzjg">
    <el-input v-model="form.j_fzjg"></el-input>
  </el-form-item>
  <el-form-item label="到期日期" prop="j_fzrq">
      <el-date-picker
      v-model="form.j_fzrq"
      type="date"
      placeholder="选择日期">
    </el-date-picker>
    <!-- <el-input v-model="form.j_fzrq"></el-input> -->
  </el-form-item>
   <el-divider></el-divider>
   <!-- <el-form-item label="单位简介" prop="j_dwjj">
       <div v-html="form.j_dwjj"></div>
    
  </el-form-item>  -->
 
        </el-form>
        <!-- <el-form-item> -->
            <div style="text-align:center;">
                <el-button type="primary" @click="onSubmit">{{button}}</el-button>
                <!-- <el-button @click="tologin">取消</el-button> -->
            </div>
                
            <!-- </el-form-item> -->
</el-card>
        
      



  </el-col>
  
</el-row>
</div>
     
  </div>
</template>

<script>
import { regionDataPlus,CodeToText  } from 'element-china-area-data'

export default {
    data(){
        return{
            formdis:true,
            button:"修改信息",
            options: regionDataPlus,
            selectedOptions: [],
            select:'',
            input3:'',
            licence:false,
            organization:false,
            legal:false,
            city:'',
            qyclass:[
               
            ],
           qyclassSel:[
                {
                    name:"生产",
                    id:"1"
                },
                 {
                    name:"加工",
                    id:"2"
                },
                 {
                    name:"销售",
                    id:"3"
                },
           ],
            form:{
 
            }

        }
    },
    created(){
        this.getUserInfo()
    },
    methods:{
        handleChange(e){
            console.log(CodeToText[e[0]]+CodeToText[e[1]]+CodeToText[e[2]])
            // CodeToText[e[0]]
            this.city=CodeToText[e[0]]+CodeToText[e[1]]+CodeToText[e[2]]
        },
        async getUserInfo(){
                const {data:res} =await this.$http.post("getLicence")
                this.form=res.data
               
              
                // this.qyclass
               
                console.log(this.form.qylx)
        },
        async onSubmit(){
          if(this.formdis==true){
                 this.formdis=false
          this.button="提交修改信息"
             }else{
                const {data:res} = await this.$http.post("editLicence",this.form)
                if(res.code==0){
                    this.formdis=true
                    this.button="修改信息"
                    this.$message.success("修改成功")
                    this.getUserInfo()
                }else{
                    this.$message.error("修改失败")
                }
             }
            
        },
        tologin(){
            this.$router.push('/login')
        },
        isgeti(e){
            if(e==3){
                this.licence=true
                this.organization=true
                this.legal=true
            }else{
                this.licence=false
                this.organization=false
                this.legal=false
            }
        }
    }

}
</script>

<style lang="less" >
.is-disabled .el-input__inner {
    color: #606266!important;
    background-color: #fff!important;
}
.el-checkbox-button.is-disabled.is-checked .el-checkbox-button__inner {
    color: #FFFFFF;
    background-color: #1890ff;
    border-color: #1890ff;
    -webkit-box-shadow: -1px 0 0 0 #74bcff;
    box-shadow: -1px 0 0 0 #74bcff;
}


// .form{
//     background-color: #fff;
//     width: 1200px;
    
// }
.danwei{
    width: 200px;
}
// .box-card {
//     width: 1200px;
//   }
.title{
    text-align: center;
}
.img {
        height: 114px;
    }

.el-select .el-input {
    width: 200px;
  }
  .el-form-item{
      width: 560px;
      margin-bottom: 15px;
  }
  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }
//  .register {
//         height: 100%;
//         background: url() bottom center no-repeat;
//         background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
//         background-image: linear-gradient(0deg,#30cfd0 0,#330867);
//         // background-image: ;
//         // url("../assets/img/zz.png"),
//         background-size: 100% 100%;
//     }
    .jianjie{
        width: 800px;
    }
    .jian{
        width: 600px;
    }
    
</style>